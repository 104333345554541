
















import Vue from 'vue';
import Component from "vue-class-component";
import {appVersion} from "@/helpers/env";

@Component({
})
export default class About extends Vue {
  private appVersion = appVersion;
  private goBackToMain(): void {
    this.$router.push({name: "home"});
  }
}
